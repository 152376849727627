$main: #bb371a;
$primary: #eee1a3;
$secondary: #eba83a;
$tertiary: #d5dbb3;
$text: #1d1d1d;
$card-shadow: 0px 10px 20px rgba(31, 35, 72, 0.1);

// GLOBAL

h1 {
  font-size: 3rem;
  color: white;
  font-family: "Arima Madurai";
}

h2 {
  font-size: 1.2rem;
  margin: 0;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  height: 100%;
  border-radius: 8px;
  box-shadow: $card-shadow;
  padding: 1rem;
}

.card--img {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(31, 35, 72, 0.5);
}

.separator {
  margin: 1rem auto;
  width: 20rem;
  opacity: 0.5;
  border-bottom: 3px solid $main;
  border-radius: 5px;
}

.link {
  &:hover {
    color: $secondary;
  }
}

span {
  padding: 0 1rem;
  background-color: $main;
  color: white;
}

// HEADER
.container__header {
  margin: 0 auto;
  color: $primary;
  font-family: Arima Madurai, cursive;
  background-image: url("../images/piano/piano_resized.jpg");
  min-height: 250px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

header {
  display: flex;
  align-items: center;
  height: 200px;
  margin: 0 4rem;
  position: relative;
  .header__title {
    width: 100%;
    span {
      color: white;
      text-shadow: 0 5px 10px rgba(0, 0, 0, 1);
      font-size: 5rem;
      background-color: rgba($main, 0.3);
    }
    h1 {
      margin: 2rem 0;
    }
  }
}

.header__flag {
  text-align: center;
  position: absolute;
  right: 2rem;
  cursor: pointer;
  width: 100px;
  background-color: rgba($main, 0.4);
  h2 {
    margin: 0;
  }
  img {
    width: 50px;
    height: 30px;
  }
}

// NAVIGATION

.nav__container {
  display: flex;
  justify-content: center;
  .nav__list {
    img {
      max-width: 200px;
      border-radius: 8px 8px 0 0;
    }
    font-family: Arima Madurai;
    color: $text;
    background-image: linear-gradient(to bottom right, $primary, $secondary);
    position: sticky;
    top: 1rem;
    margin: 2rem 0;
    transition: all 0.5s;
    border-radius: 8px;
    box-shadow: $card-shadow;
    max-height: 590px;
  }
}

.nav__list__item {
  margin: 0rem 2rem;
  cursor: pointer;
  &:hover {
    color: $secondary;
  }
}
// MAIN

.container--main {
  display: flex;
  padding: 2rem 4rem;
  background-image: url("../images/bcg-waves.svg");
}

.container {
  width: 100%;
  padding: 2rem 6rem;
}

// HOME
.container--home {
  margin: 0 auto;
  max-width: 900px;
  background-color: $primary;
  background-image: url("https://www.transparenttextures.com/patterns/skulls.png");
  border-radius: 8px;
  box-shadow: $card-shadow;
  padding: 1rem;
  img {
    border-radius: 8px;
  }
}

// BIO
.container__page {
  @extend .card;
  text-align: justify;
  .bio--top {
    img {
      @extend .card--img;
    }
  }

  p {
    margin: 0 0 0.5rem;
  }
}

.bio--top {
  float: left;
  margin: 0 1rem 0 0;
  img {
    width: 300px;
    height: 265px;
  }
  h5 {
    margin: 0 0 0.5rem 0;
  }
}

// Disco

.disco__page {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 1rem auto;
  .disco__item {
    width: 300px;
    padding: 1rem;
    transition: all 0.2s ease;
    border-radius: 8px;
    > div {
      display: flex;
      > div {
        margin: 1rem 0 1rem 2rem;
        cursor: pointer;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .disco__logo {
            width: 5rem;
            height: 1.5rem;
          }
          .disco__logo--deezer {
            width: 5rem;
            height: 1rem;
          }
          transition: transform 0.2s ease-out;
          &:hover {
            transform: scale(1.1) translateX(0) translateY(0);
          }
        }
      }
    }
    &:hover {
      box-shadow: 0px 0px 10px rgba(31, 35, 72, 0.5);
    }
    h2 {
      margin: 0.5rem 0;
    }
    .disco__img {
      box-shadow: $card-shadow;
      border-radius: 0;
      width: 150px;
      height: 150px;
      object-fit: cover;
      cursor: pointer;
      transition: transform 0.2s ease-out;
      &:hover {
        transform: scale(1.06) translateX(5px) translateY(5px);
      }
    }
  }
}

// PRESS PAGE
.press__page {
  p {
    line-height: 1.5rem;
  }
  span {
    font-size: 1.1rem;
    font-weight: 500;
    font-style: italic;
  }
}

// PHOTO PAGE
.container--page {
  width: 800px;
  margin: auto;
}

// CONCERTS
.container__page--concert {
  background-image: url("../images/photos/slide21.jpg");
  background-color: $main;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include center();
  padding: 1.5rem;
}
.concerts {
  background-color: $primary;
  opacity: 0.9;
  padding: 0 2rem;
  h1 {
    text-align: center;
  }
  p {
    margin: 1rem 0;
    font-size: 1.4rem;
  }
}

// GUEST BOOK
.guestbook {
  height: 100%;
  border-radius: 8px;
  box-shadow: $card-shadow;
  padding: 1rem 3rem;
  background: rgba($main, 0.9);
  border: 1px solid rgba($secondary, 0.25);
  h1 {
    margin-top: 0;
    text-align: center;
  }
  p {
    color: $primary;
    text-align: justify;
    line-height: 1.5rem;
  }
  img {
    width: 4rem;
  }
  .piano {
    display: flex;
    justify-content: center;
  }
}

.guestbook__title {
  p {
    font-style: italic;
    font-size: 1.2rem;
  }
  a:link,
  a:visited {
    color: $secondary;
    transition: all 0.2s ease-out;
    &:hover {
      color: $tertiary;
    }
  }
}

.guestbook__messages {
  margin: 1rem 0 2rem;
  h2 {
    color: $secondary;
    letter-spacing: 0.5px;
  }
}

// PRO PAGE
.container__page--pro {
  display: flex;
  align-items: center;
  // justify-content: center;
  background-color: $primary;
  height: 100%;
  border-radius: 8px;
  box-shadow: $card-shadow;
  padding: 1rem;
}
.pro__page {
  margin: 0 2rem;
  span {
    font-size: 2rem;
    font-weight: 700;
    font-family: Arima Madurai;
    padding: 0 1rem;
    color: white;
    background-color: $main;
  }
  h4 {
    a:link,
    a:visited {
      color: $text;
      &:hover {
        text-decoration: none;
        p {
          color: $main;
        }
      }
    }
  }
}
// FOOTER

.container__footer {
  width: 100%;
}
footer {
  background-color: $tertiary;
  @include center;
  margin: 0;
  h5 {
    margin-right: 2rem;
    cursor: pointer;
  }
  a:link,
  a:visited {
    color: $text;
    text-decoration: none;
    &:hover {
      color: $main;
      text-decoration: none;
    }
  }
}

// CONTACT
.contact {
  background-image: url("../images/piano/piano3.jpg");
  background-size: cover;
  padding: 2rem;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(31, 35, 72, 0.1);
  height: 100%;
  > div {
    display: flex;
    flex-direction: column;
  }
  h1 {
    margin: 2rem 0;
  }
  h2 {
    margin: 2rem 0;
  }
  h3 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    color: $secondary;
  }
  a:link,
  a:visited {
    color: $secondary;
    transition: all 0.2s ease-out;
    &:hover {
      color: $tertiary;
    }
  }
}

// LINKS

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-top: 0;
  }
  h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  h3 {
    a {
      display: inline-block;
      width: 200px;
    }
    span {
      background-color: transparent;
      color: $main;
    }
  }
}

// MODAL
.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.5em 1em;
  z-index: 999999;
}

.modal__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-close {
  position: absolute;
  margin: 0;
  right: 15px;
  top: 10px;
  color: $main;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.5rem 1.5rem;
  background: rgba($primary, 0.749);
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  &:hover {
    background: $primary;
    color: $text;
  }
}

.modal__content {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 10rem;
  background: rgba($main, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  max-height: 100%;
  width: 100%;
  padding: 15px 20px;
  color: $primary;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $primary $main;
}

.modal__title {
  margin-left: 0.5rem;
  h1 {
    margin: 0;
  }
  p {
    margin: 0 0 0.5rem 0;
  }
  span {
    background-color: transparent;
  }
  a:link,
  a:visited {
    color: white;
    text-decoration: none;
    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
  }
  .modal__title--links {
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
    > div {
      margin-right: 1rem;
    }
    margin: 0 0 0.5rem 0;
    img {
      height: 1.5rem;
      margin: 0 0.5rem;
    }
    a {
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }
    a:link,
    a:visited {
      color: $primary;
      text-decoration: none;
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}
.modal__tracklist {
  display: flex;
  background-color: rgb(248, 248, 248);
  width: 100%;
  height: 500px;
  border-radius: 10px;
  > div {
    display: flex;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $tertiary $main;
  }
  p {
    margin: 0;
    color: $text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    min-width: 185px;
    text-overflow: ellipsis;
  }
  img {
    border-radius: 10px 0 0 10px;
    margin-right: 1rem;
    height: 500px;
    width: 500px;
    object-fit: cover;
  }
  .modal__sounds > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
  }
  figure {
    @include center;
    margin: 0 1rem 0 0;
    audio {
      border-radius: 10px;
    }
  }
}
